.suggestedAddress {
  height: 52px;
  width: 100%;
}

:global {
  .beam-modal__body {
    div:nth-child(2) {
      display: flex;
      justify-content: center;
    }
  }
  .beam-font--bodySmallBold {
    align-self: center;
    margin-bottom: 0px;
  }
}
