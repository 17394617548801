@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
@use '@vst/beam/sass/vars/typography/variant';

.rootContainer {
  width: 100%;
}

.scheduleInstallationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 719px) {
    border-radius: 0 !important;
  }

  @media screen and (max-width: 520px) {
    flex-direction: column;
    align-items: start;
  }

  .link {
    @media screen and (max-width: 520px) {
      margin-top: 8px;
    }
  }
}

.children {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90%;
}
