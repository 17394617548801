.errorContainer {
  display: flex;
  flex-direction: column;
  background-color: color(white);
  border-radius: 16px;
  align-items: center;
  padding: 32px 24px;
  @media screen and (max-width: 768px) {
    border-radius: 0;
    padding: 40px 16px;
  }
}
