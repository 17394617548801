@use 'sass:map';
@use '@vst/beam/sass/utils/colors';

// Map
$gradient-colors: (
  'blue': (
    'blue_700',
    'teal_500',
  ),
  'green': (
    'teal_600',
    'green_300',
  ),
  'orange': (
    'alert_300',
    'warm_200',
  ),
  'warm': (
    'warm_700',
    'warm_100',
  ),
  'violet': (
    'violet_700',
    'violet_100',
  ),
  'pink': (
    'pink_700',
    'pink_100',
  ),
  'teal': (
    'teal_800',
    'teal_300',
  ),
  'red': (
    'alert_400',
    'alert_200',
  ),
  'gray': (
    'gray_900',
    'gray_300',
  ),
);

$badge-colors: (
  'blue': (
    'blue_300',
    'info_500',
  ),
  'green': (
    'success_200',
    'success_500',
  ),
  'orange': (
    'warning_200',
    'warning_500',
  ),
  'warm': (
    'warning_300',
    'alert_300',
  ),
  'violet': (
    'violet_100',
    'violet_600',
  ),
  'pink': (
    'pink_100',
    'pink_600',
  ),
  'teal': (
    'teal_300',
    'teal_700',
  ),
  'red': (
    'alert_200',
    'warning_500',
  ),
  'gray': (
    'gray_300',
    'gray_500',
  ),
);

$title-addOns-colors: (
  'blue': 'teal_500',
  'warm': 'warm_300',
  'green': 'green_400',
  'violet': 'violet_300',
  'pink': 'pink_300',
  'teal': 'teal_400',
  'orange': 'warning_400',
  'red': 'alert_300',
  'gray': 'gray_500',
);

// Use the maps
@mixin background-gradient($color) {
  @each $color-name, $color-code in $gradient-colors {
    @if $color-name == $color {
      &:before {
        background: linear-gradient(
          90deg,
          map.get(colors.$raw-colors, '#{nth($color-code, 1)}') 0.51%,
          map.get(colors.$raw-colors, '#{nth($color-code, 2)}') 100.51%
        );
      }
    }
  }
}

@mixin custom-bg-color($color) {
  @each $color-name, $color-code in $badge-colors {
    @if $color-name == $color {
      @extend .planBadge;
      background-color: rgba($color: map.get(colors.$raw-colors, '#{nth($color-code, 1)}'), $alpha: 0.3);
      color: map.get(colors.$raw-colors, '#{nth($color-code, 2)}');
    }
  }
}

@mixin set-bg-color($color) {
  @each $color-name, $color-code in $title-addOns-colors {
    @if $color-name == $color {
      &::before {
        background-color: map.get(colors.$raw-colors, '#{nth($color-code, 1)}');
      }
    }
  }
}
