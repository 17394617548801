@use 'sass:map';
@use '@vst/beam/sass/utils/colors';

.downloadSpeedContainer {
  display: flex;
  align-items: flex-end;
  white-space: break-spaces;
}

.padding {
  display: inline-block;
  margin-right: 2px;
}
