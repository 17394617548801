p.bodyText {
  margin-bottom: 0;
}

.bodyModalText {
  :global {
    .beam-modal__content {
      @media screen and (max-width: 619px) {
        margin-top: 16px;
      }
    }
  }
}
