@use 'sass:map';
@use '@vst/beam/sass/utils/colors';

.container {
  background: linear-gradient(
    90deg,
    map.get(colors.$raw-colors, 'gray_200') 0%,
    map.get(colors.$raw-colors, 'gray_100') 25%,
    map.get(colors.$raw-colors, 'gray_200') 50%,
    map.get(colors.$raw-colors, 'gray_100') 75%,
    map.get(colors.$raw-colors, 'gray_200')
  );
  background-size: 200% 100%;
  animation: skeleton-loading 1s linear infinite;
  display: block;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.xs {
  height: 16px;
}

.sm {
  height: 20px;
}

.md {
  height: 24px;
}

.lg {
  height: 36px;
}

.xl {
  height: 40px;
}

.fullHeight {
  height: 100%;
}
