@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
@import '../mixins/mixins';

.card {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100%;
  padding-top: 24px;

  &:before {
    @include background-gradient('blue');
    content: '';
    height: 8px;
    width: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 16px 24px;
  }
}
