@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
@import '../mixins/mixins';

.flex {
  flex: 1 0 0;
}

.planTitleAddOns {
  position: relative;
  padding-left: 8px;
  @include set-bg-color('gray');

  &::before {
    content: '';
    width: 4px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.blueTitle {
  @include set-bg-color('warm');
}

.greenTitle {
  @include set-bg-color('green');
}

.orangeTitle {
  @include set-bg-color('orange');
}

.warmTitle {
  @include set-bg-color('warm');
}

.violetTitle {
  @include set-bg-color('violet');
}

.pinkTitle {
  @include set-bg-color('pink');
}

.tealTitle {
  @include set-bg-color('teal');
}

.redTitle {
  @include set-bg-color('red');
}
