@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
@use '@vst/beam/sass/vars/typography/variant';

.container {
  .column {
    margin-top: 0px;
  }
}

.spaceBetween {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.headline {
  @extend .spaceBetween;
  align-items: center;
}

.changeOrRenew {
  --beam-color-link--border-color: transparent;
  --beam-color-link--primary-active: var(--beam-color-link--primary);
}

.changeOrRenew > * {
  :global {
    color: var(--beam-color-blue_600);
  }
}

.planNameAndPrice {
  @extend .spaceBetween;
}

.planTitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (min-width: 960px) and (max-width: 1100px) {
    max-width: 65%;
  }
}

.planPrice {
  @extend .spaceBetween;
  display: flex;
  flex-direction: column;
  align-self: baseline;
  justify-content: end;
  align-items: end;
}

.equipmentFee {
  @extend .spaceBetween;
  border-bottom: 1px solid map.get(colors.$raw-colors, 'gray_200');
}

.totalPlanPriceSurface {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.totalPlanPrice {
  align-self: end;
}

.strikethrough {
  text-decoration-line: line-through;
}

.noPromo {
  @extend .spaceBetween;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.leaseFeeName {
  max-width: 175px;
  :global {
    .beam-font--bodySmallRegular {
      font: 400 1pc/20px Source Sans Pro;
    }
  }
}

.contractDuration {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 12px;
}

.skeleton > * {
  margin: 4px 0px 0px auto;
}
.planName {
  max-width: 175px;
}
