@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
@import 'mixins/mixins';

.cartContainer {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;

  @media screen and (min-width: 720px) {
    border-radius: var(--beam-surface--radius_16px);
  }
}

.cartClosed {
  @media screen and (max-width: 719px) {
    opacity: 0;
    max-height: 0px;
  }
}

.cartOpened {
  @media screen and (max-width: 719px) {
    opacity: 1;
    max-height: 100%;
    border-top: 1px solid map.get(colors.$raw-colors, 'gray_200');
  }
}

.cardPadding {
  @media screen and (min-width: 720px) {
    padding: 24px 24px 24px 24px;
  }
  @media screen and (max-width: 719px) {
    padding: 32px 16px 32px 16px;
  }
}

.blueCard {
  @include background-gradient('blue');
}

.greenCard {
  @include background-gradient('green');
}

.orangeCard {
  @include background-gradient('orange');
}

.warmCard {
  @include background-gradient('warm');
}

.violetCard {
  @include background-gradient('violet');
}

.pinkCard {
  @include background-gradient('pink');
}

.tealCard {
  @include background-gradient('teal');
}

.redCard {
  @include background-gradient('red');
}

.grayCard {
  @include background-gradient('gray');
}
