.radioButton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

:global {
  .beam-radio-button label {
    span {
      font: 700 16px/20px 'Source Sans Pro';
    }
  }
}
