@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
@use '@vst/beam/sass/vars/typography/variant';

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes stretch {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -124px;
  }
}

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none !important;

  &.small {
    --size: 16px;
    gap: 8px;
  }

  &.medium {
    --size: 32px;
    gap: 16px;
  }

  &.large {
    --size: 60px;
    align-self: center;
    flex-direction: column;
    gap: 24px;
  }

  &.primary {
    --color: var(--beam-color-teal_600);
  }

  &.secondary {
    --color: var(--beam-color-gray_400);
  }
}

.svg {
  width: var(--size);
  height: var(--size);
  animation: rotate 2s linear infinite;
}

.svg circle {
  fill: none;
  stroke: var(--color);
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: stretch 1.5s ease-in-out infinite;
}

.text {
  max-width: 249px;
  margin: auto;
  text-align: center;

  @media screen and (max-width: 719px) {
    max-width: 288px;
  }
}

.headingText {
  max-width: 488px;
  margin: auto;
  text-align: center;

  @media screen and (max-width: 719px) {
    max-width: 288px;
  }
}

.fullPageLoadingSpinner {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
