@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
@use '@vst/beam/sass/vars/typography/variant';

.spaceBetween {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.dueToday {
  display: inline-block;
}

.borderBottom {
  border-bottom: 1px solid map.get(colors.$raw-colors, 'gray_200');
}

.borderTop {
  border-top: 1px solid map.get(colors.$raw-colors, 'gray_200');
}

.container {
  @extend .borderBottom;
}

.additionalFees {
  @extend .spaceBetween;
}

.dueTodayTotal {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  @extend .borderTop;
}

.dueTodayTotalNoBorderTop {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
}

.noAdditionalFees {
  @extend .dueTodayTotal;
  @extend .borderBottom;
}

.priceCalculatedLater {
  color: var(--beam-color-gray_600);
}

.dueTodayHeadline {
  @extend .spaceBetween;
}

.dueTodayValue,
.dueTodayEmpty {
  align-self: end;
}

.strikethrough {
  text-decoration-line: line-through;
}

.planPrice {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.installationFeeWithPromo {
  align-self: end;
}

.noPromo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
