@use 'sass:map';
@use '@vst/beam/sass/utils/colors';

.container {
  .button {
    --beam-btn-tertiary-hover-background: transparent;
    --beam-btn-tertiary-pressed-background: transparent;
    --beam-btn-tertiary-default-color: var(--beam-color-blue_600);
    --beam-btn-padding-h: 0px;
    --beam-btn-padding-v: 0px;
    border-radius: 0px;
    font-weight: 400;
    min-width: 0;
    text-decoration: underline;
  }
}
