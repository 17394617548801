.item {
  display: flex;
  justify-content: space-between;

  .price {
    display: flex;

    .priceText {
      text-align: right;

      .free {
        text-transform: uppercase;
      }
    }
  }

  .crossout {
    text-decoration: line-through;
  }
}
