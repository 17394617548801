@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
.visibilityIcon {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 24px;
}

.taxIdErrorInput {
  [class*='beam-font--labelSmall'] {
    color: map.get(colors.$raw-colors, 'alert_400');
  }
}
