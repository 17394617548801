@use 'sass:map';
@use '@vst/beam/sass/utils/colors';

.flex {
  display: flex;
  align-items: flex-end;
}

.downloadUnits {
  line-height: 2;
}
