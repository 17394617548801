.bodyText {
  :global {
    .beam-modal__content {
      @media screen and (min-width: 513px) and (max-width: 517px) {
        margin-top: 16px;
      }
      @media screen and (max-width: 429px) {
        margin-top: 16px;
      }
    }
  }
}
