@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
@use '@vst/beam/sass/vars/typography/variant';

.container {
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
}

.textWrapper {
  &:hover {
    cursor: pointer;
  }
}

.container > * {
  :global {
    color: var(--beam-color-blue_600);
  }
}
