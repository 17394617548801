.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .editBtn {
    --beam-color-link--border-color: transparent;
    --beam-color-link--primary-active: var(--beam-color-link--primary);
  }
}

.total {
  border-top: 1px solid var(--beam-color-gray_300);
  text-align: right;
}
