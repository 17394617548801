@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
@use '@vst/beam/sass/vars/typography/variant';

.container {
  border-bottom: 1px solid map.get(colors.$raw-colors, 'gray_200');

  .column {
    margin-top: 0;
  }
}

.spaceBetween {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.heading {
  @extend .spaceBetween;
}

.promotionDetails {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
}

.promotionText,
.promotionExtraDetails {
  align-self: end;
}

.skeletonSpacing {
  margin-bottom: 2px;
  margin-top: 2px;
}

.totalAfterPromotion {
  display: flex;
  justify-content: end;
}
