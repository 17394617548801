@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
@use '@vst/beam/sass/vars/typography/variant';

.wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  .scheduleLaterModalContainer {
    .scheduleInstallationMiddle {
      display: block;
    }
  }
}

.modalDescription {
  display: inline-block;
  max-width: 320px;
  margin: auto;
}

.scheduleInstallationTop {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 520px) {
    flex-direction: column;
    align-items: start;
  }

  .link {
    text-decoration: underline;
    line-height: 20px;
    @media screen and (max-width: 520px) {
      margin-top: 16px;
    }
  }
}
