@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
@use '@vst/beam/sass/vars/typography/variant';

.cartHeaderContainer {
  background-color: var(--beam-surface--primary);
}

.cartHeaderWrapper {
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  justify-content: space-between;
}

.centerAlignment {
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftSide,
.rightSide {
  @extend .centerAlignment;
}

.cartDueMonthly {
  display: flex;
  justify-content: flex-end;
}
