@use 'sass:map';
@use '@vst/beam/sass/utils/colors';

.mainContainer {
  padding-inline: var(--beam--component-x-paddings);
  padding-block: 24px;

  @media screen and (max-width: 719px) {
    padding-inline: 16px;
    flex-direction: column-reverse;
  }
}

.mainContainerNoPadding {
  @extend .mainContainer;
  @media screen and (max-width: 719px) {
    padding-top: 0px;
  }
}

.backButtonContainer {
  display: flex;
  align-items: center;
  padding-left: 0px;

  &:empty {
    margin-top: 0;
  }

  @media screen and (max-width: 719px) {
    justify-content: space-around;
    &:not(:empty) {
      margin-top: 12px;
    }
  }
}

.skipButtonContainer {
  padding: 0px;

  &:empty {
    margin-top: 0;
  }

  @media screen and (min-width: 720px) {
    padding-right: 12px;
  }
}

.skipButton {
  white-space: nowrap;
  @media screen and (max-width: 719px) {
    margin: 12px 0px;
  }
}

.confirmButtonContainer {
  white-space: nowrap;
  padding: 0px;
}
