.root {
  height: 1px;
  border: none;
  background-color: var(--beam-color-gray_200);

  &.normal {
    margin: 0;
  }

  &.inside {
    margin-block: 0;
    margin-inline: var(--beam--component-x-paddings);
  }

  &.outside {
    margin-block: 0;
    margin-inline: calc(var(--beam--component-x-paddings) * -1);
  }

  &.outside-2 {
    margin-block: 0;
    margin-inline: calc(var(--beam--component-x-paddings) * -2);
  }
}
