.FAQcontainer {
  display: flex;
  justify-content: space-between;
}

.ACPModalShowDetailsContainer {
  flex-direction: column;
  .eligibleTitle {
    align-self: start;
  }
}

.AcpModalDetailsIcon {
  align-items: center;
}

.noSecondaryButton {
  :global {
    .beam-btn--secondary {
      display: none;
    }
    .beam-btn--primary {
      margin-bottom: 0px;
    }
  }
}

.eligibleTitle {
  :global {
    margin-bottom: '16px';
  }
}

.smallFontSizeLink {
  font-size: 16px;
}
