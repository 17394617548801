@use 'sass:map';
@use '@vst/beam/sass/utils/colors';

.root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  --beam--xl-container-max-width: 1248px;
  border-bottom: 1px solid map.get(colors.$raw-colors, 'gray_200');
}

.headerContainer {
  overflow-x: hidden;
}

.main {
  flex: 1 0 auto;
  overflow: hidden;
  --beam--xl-container-max-width: 1032px;
  --beam--lg-container-max-width: 100%;
  --beam--md-container-max-width: 672px;
  --beam--gutter-y: 24px;

  @media screen and (max-width: 719px) {
    --beam--gutter-y: 8px;
  }
  .content {
    min-height: fit-content;
    display: flex;
    justify-content: center;
    padding-bottom: 0;

    .loading {
      display: none;
    }

    .loaded {
      display: block;
      width: 100%;

      @media screen and (min-width: 720px) {
        padding-bottom: 0;
      }
    }

    .payments-page-loaded {
      @extend .loaded;

      @media screen and (max-width: 719px) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    border-radius: var(--beam-surface--radius_16px);

    @media screen and (max-width: 719px) {
      border-radius: 0;
      padding-inline: 0;
      padding-block: 8px;
    }
  }
  .loadingContent {
    min-height: 450px;
  }
}

.main {
  .asideContainer {
    @media screen and (max-width: 719px) {
      margin-top: 0px;
    }

    .aside {
      @media screen and (min-width: 720px) {
        border-radius: var(--beam-surface--radius_16px);
        padding: 0px;
      }

      @media screen and (max-width: 719px) {
        padding-bottom: 8px;
      }
    }
  }
}
