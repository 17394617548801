@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
@use '@vst/beam/sass/vars/typography/variant';

.noCloseButton {
  :global {
    .beam-close-button {
      display: none;
    }

    .beam-modal--standard,
    .beam-modal__header {
      padding: 24px;
    }
  }
}

.noPrimaryActionBottomMargin {
  :global {
    .beam-btn--primary {
      margin-bottom: 0px;
    }
  }
}

.noSecondaryButton {
  :global {
    .beam-btn--secondary {
      display: none;
    }
  }
}

.actionPrimaryFullWidth {
  :global {
    .beam-btn--primary {
      min-width: fit-content;
    }
  }
}

.noWrapText {
  :global {
    .beam-btn--primary {
      white-space: nowrap;
    }
  }
}

.actionSecondaryFullWidth {
  :global {
    .beam-btn--secondary {
      white-space: nowrap;
      min-width: fit-content;
    }
  }
}

.linkSecondaryButton {
  :global {
    .beam-combined-buttons__actions {
      width: 100%;

      @media screen and (min-width: 513px) {
        justify-content: space-between;
      }

      .beam-btn--primary {
        @media screen and (min-width: 513px) {
          max-width: fit-content;
        }
      }

      .beam-btn--secondary {
        @media screen and (min-width: 513px) {
          justify-content: flex-start;
          max-width: fit-content;
          margin-right: 0px;
          padding-right: 0px;
        }

        box-shadow: none !important;
        border: none;
        text-decoration: underline;
        font-weight: 400;
        color: var(--beam-color-blue_600);
      }

      .beam-btn--secondary:focus {
        background: var(--beam-color-white);
      }
    }
  }
}

.noMarginBottomFromText {
  :global {
    .beam-modal__content p {
      margin-bottom: 0px;
      min-height: 0px;
    }
  }
}

.alignTextToLeft {
  :global {
    justify-content: normal;
  }
}

:global {
  .beam-modal--content-scrolled-down:before {
    z-index: 1;
  }
  .beam-modal {
    display: flex !important;
    min-height: 0px !important;
  }
}

.fullHeightMobileModal {
  @media (max-width: 512px) {
    :global {
      --beam-modal--mobile-max-height: 100%;
      height: 100%;
      .beam-modal__content {
        display: flex;
        justify-content: center;
      }
    }
  }
}
