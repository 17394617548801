.overlayWrap {
  z-index: 1;
}

@mixin modal-multiline-height($min: 52px) {
  --beam-modal--header-height: minmax($min, max-content) !important;
}

.modal {
  @include modal-multiline-height();

  :global(.beam-modal__body:has(> div:empty)) {
    padding: 0;
  }

  :global(.beam-modal__header) {
    @include modal-multiline-height();
  }
}

.addonsContainer {
  .subTitle {
    margin-bottom: 16px;
  }
}

.title-addon-name {
  white-space: nowrap;
}

.subtitle-addon-name {
  white-space: nowrap;
  font-weight: bold;
}

.benefitsList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .benefitsItem {
    display: flex;
    margin-bottom: 0;
    padding-bottom: 8px;

    svg {
      flex-shrink: 0;
      margin-top: 6px;
    }
  }

  .crossoutItem {
    text-decoration: line-through;
  }
}
