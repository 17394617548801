@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
@use '@vst/beam/sass/vars/typography/variant';

:global {
  html,
  body,
  #root {
    height: 100%;
  }
}

.root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--beam-surface--secondary);
  --beam--xl-container-max-width: 1080px;
  --beam--lg-container-max-width: 100%;
  --beam--md-container-max-width: 720px;
}

.header {
  --beam--xl-container-max-width: 1248px;
  overflow-x: hidden;
}

.main {
  flex: 1 0 auto;
  overflow: hidden;
}

.borderBottom {
  border-bottom: 1px solid map.get(colors.$raw-colors, 'gray_200');
}

.content {
  @media screen and (max-width: 719px) {
    --beam--row-edge-2-edge-offset: var(--beam--outermost-col-padding);
  }
}

.heading {
  text-align: center;

  @media screen and (max-width: 719px) {
    & .heading-text {
      @include variant.make-variants-styles('heading 4');
    }
  }

  @media screen and (min-width: 720px) {
    --beam-surface--primary: transparent;
  }
}

.card {
  @media screen and (max-width: 719px) {
    border-radius: 0;
  }
}
