@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
@use '@vst/beam/sass/vars/typography/variant';

.containerProgressBar {
  @media screen and (max-width: 719px) {
    background-color: var(--beam-surface--primary);

    & .heading-text {
      @include variant.make-variants-styles('heading 4');
    }
  }
}

.heading-text,
.body-text {
  text-align: center;
}

.container {
  margin-inline: auto;
}

.progressBar {
  height: 4px;
  border-radius: 4px;
  margin-inline: auto;
  background-color: map.get(colors.$raw-colors, 'gray_200');

  @media screen and (min-width: 720px) {
    max-width: 552px;
  }
}

.filled {
  height: 100%;
  border-radius: inherit;
  background-color: map.get(colors.$raw-colors, 'teal_600');
}
