@use 'sass:map';
@use '@vst/beam/sass/utils/colors';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  @media screen and (max-width: 720px) {
    top: auto;
    bottom: 0;
  }
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: map.get(colors.$raw-colors, 'white');
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 448px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  overflow: hidden;
  border-radius: 16px;

  @media screen and (max-width: 720px) {
    max-width: 100%;
    border-radius: 0;
    height: auto;
    max-height: initial;
    top: auto;
    bottom: 0;
    transform: none;
    left: 0;
  }
}

.header {
  width: 100%;
  position: relative;
  min-height: 74px;
  padding: 0 24px;
}

.close {
  position: absolute !important;
  min-width: initial !important;
  top: 22px;
  right: 0;
  z-index: 2;
  &:hover {
    background: var(--beam-btn-primary-background) !important;
  }
}

.icon {
  color: #32424e;
}

.main {
  height: calc(100% - 138px);
  padding: 0 24px;
  overflow: auto;
}

.footer {
  width: 100%;
  border-top: 1px solid map.get(colors.$raw-colors, 'gray_200');
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 24px;
  min-height: 88px;
}

.primaryButton {
  @media screen and (max-width: 720px) {
    width: 100%;
    button {
      width: 100%;
    }
  }
}
