@use 'sass:map';
@use '@vst/beam/sass/utils/colors';
@import '../mixins/mixins';

.planBadge {
  border-radius: 4px;
  @include custom-bg-color('blue');
}

.viasatVoice {
  text-transform: uppercase;
}

.greenBadge {
  @include custom-bg-color('green');
}

.orangeBadge {
  @include custom-bg-color('orange');
}

.warmBadge {
  @include custom-bg-color('warm');
}

.violetBadge {
  @include custom-bg-color('violet');
}

.pinkBadge {
  @include custom-bg-color('pink');
}

.tealBadge {
  @include custom-bg-color('teal');
}

.redBadge {
  @include custom-bg-color('red');
}

.grayBadge {
  @include custom-bg-color('gray');
}
