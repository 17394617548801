@use 'sass:map';
@use '@vst/beam/sass/utils/colors';

.root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  --beam--xl-container-max-width: 1248px;
  border-bottom: 1px solid map.get(colors.$raw-colors, 'gray_200');
}

.headerContainer {
  overflow-x: hidden;
  .loadingSpinner {
    width: 525px !important;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 719px) {
      width: 100%;
      position: static;
      transform: none;
      height: 90vh;
    }
  }
}
