@use 'sass:map';
@use '@vst/beam/sass/utils/colors';

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 1440px) {
    --beam--component-x-paddings: 0px;
  }

  .buttonIcon {
    --beam-btn-tertiary-hover-background: transparent;
    --beam-btn-tertiary-pressed-background: transparent;
    padding: 0px;
    min-width: initial;
  }
}

.contactUsContainer {
  display: flex;
  align-items: center;
}

.contactUsContainer:has(a[href='']) {
  display: none;
}
.link[href=''] {
  display: none;
}

@media screen and (max-width: 720px) {
  .hidden {
    display: none;
  }
}
