@use 'sass:map';
@use '@vst/beam/sass/utils/colors';

.inputContainer {
  display: flex;
  @media screen and (max-width: 375px) {
    gap: 12px;
    flex-direction: column;
  }
}

.labelContainer {
  display: flex;
  align-items: center;
}

.labelText {
  padding-right: 6px;
}
