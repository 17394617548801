@use 'sass:map';
@use '@vst/beam/sass/utils/colors';

.dataCapContainer {
  display: flex;
  align-items: flex-end;
}

.dataCapUnits {
  line-height: 2;
}

.dataCap {
  align-self: flex-end;
}

.downloadSpeedContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
}

.paddedText {
  padding-bottom: 24px;
}
