@use 'sass:map';
@use '@vst/beam/sass/utils/colors';

.cartDescriptionContainer {
  padding-bottom: 24px;
  border-bottom: 1px solid map.get(colors.$raw-colors, 'gray_200');
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
